import { useAuth0 } from '@auth0/auth0-react';
import { Dialog } from '@headlessui/react';
import { ClipboardCheckIcon } from '@heroicons/react/outline';
import { XCircleIcon, XIcon } from '@heroicons/react/solid';
import React, { useState, ChangeEvent, useContext } from 'react';

import {
  FILE_TYPE,
  Car,
  User,
  Company,
  Transaction,
  Bate,
} from '../../../types/types';
import Spinner from '../../../components/Spinner';
import useUploadFile from '../../../hooks/useUploadFile';
import {
  editUser,
  editVehicle,
  editCompany,
  editTransaction,
  editBate,
} from '../../../services/services';
import { toasterContext } from '../../../context/ToasterContext/ToasterContext';
import BaseModal from '../../../components/BaseModal';
import { ServiceTypeI } from '../../../enums/serviceTypeDict';
import { skodaOCR } from '../../../services/skoda';

type UploadFileModalProps = {
  showModal: boolean;
  closeModal: () => void;
  fileType: FILE_TYPE;
  actionAppliedTo: Car | User | Company | Transaction | Bate;
  forceUpdate: () => void;
  tramitType?: 'transaction' | 'bate' | 'registration';
};

function UploadFileModal({
  showModal,
  closeModal,
  fileType,
  actionAppliedTo,
  forceUpdate,
  tramitType,
}: Readonly<UploadFileModalProps>) {
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [file, setFile] = useState<File | null>(null);

  const { uploadFile, getS3SignedUrl } = useUploadFile();
  const { getAccessTokenSilently } = useAuth0();
  const { setToasterData } = useContext(toasterContext);

  const uploadDocument = async () => {
    const token = await getAccessTokenSilently();
    try {
      setIsLoading(true);
      const fileKey = await uploadFile(file);
      const uri = await getS3SignedUrl(fileKey);
      switch (fileType) {
        case FILE_TYPE.DOI_BACK: {
          const ocrData = await skodaOCR('doi', {
            uri,
          });
          const { city, address, ccaa, province, zipCode } = ocrData;
          await editUser(token, actionAppliedTo.id, {
            city,
            address,
            ccaa,
            province,
            zipCode,
            photoDni: [(actionAppliedTo as User)?.photoDni?.[0], fileKey],
          });
          break;
        }
        case FILE_TYPE.DOI_FRONT: {
          const ocrData = await skodaOCR('doi', {
            uri,
          });
          const {
            dni,
            name,
            firstSurname,
            secondSurname,
            birthDate,
            sex,
            expiryDate,
          } = ocrData;
          await editUser(token, actionAppliedTo.id, {
            dni,
            name,
            surname: `${firstSurname} ${secondSurname}`,
            birthDate,
            sex,
            dniExpiryDate: expiryDate,
            photoDni: [fileKey, (actionAppliedTo as User)?.photoDni?.[1]],
          });
          break;
        }
        case FILE_TYPE.CIRCULATION_PERMIT: {
          const ocrData = await skodaOCR('circulation_permit', {
            uri,
          });
          const {
            plate,
            enrollmentDate,
            brand,
            model,
            frameNumber,
            powerKw,
            cc,
            serviceType,
            fuel,
            kms,
          } = ocrData;
          await editVehicle(token, {
            id: actionAppliedTo.id,
            plate,
            enrollmentDate,
            brand,
            model,
            kms,
            cc,
            powerKw,
            frameNumber,
            serviceType: serviceType as ServiceTypeI,
            fuel,
            circulationPermit: fileKey,
          });
          break;
        }
        case FILE_TYPE.TECHNICAL_SHEET:
          await editVehicle(token, {
            id: actionAppliedTo.id,
            technicalSheet: fileKey,
          });
          break;
        case FILE_TYPE.PRO_INVOICE: {
          if (tramitType === 'transaction') {
            await editTransaction(
              token,
              (actionAppliedTo as Transaction).transactionCode,
              {
                proInvoice: fileKey,
              },
            );
          }
          if (tramitType === 'bate') {
            await editBate(token, (actionAppliedTo as Bate).bateCode, {
              proInvoice: fileKey,
            });
          }
          break;
        }
        case FILE_TYPE.TIF: {
          const ocrData = await skodaOCR('tif', {
            uri,
          });
          const {
            nif,
            legalName,
            fiscalAddressZipCode,
            fiscalAddressState,
            fiscalAddressProvince,
            fiscalAddressCity,
            fiscalAddressAddress,
          } = ocrData;
          await editCompany(token, (actionAppliedTo as Company).id, {
            nif,
            legalName,
            nifFile: fileKey,
            fiscalAddressZipCode,
            fiscalAddressState,
            fiscalAddressProvince,
            fiscalAddressCity,
            fiscalAddressAddress,
          });
          break;
        }
        default:
          break;
      }
      setToasterData({
        type: 'SUCCESS',
        title: 'Documento subido correctamente',
        message: 'Documentación guardada y procesada correctamente',
      });
      forceUpdate();
    } catch (err) {
      setToasterData({
        type: 'ERROR',
        title: 'Error subiendo documentación',
        message:
          'Ha ocurrido algún problema procesando la documentación. Intentalo más tarde.',
      });
      setHasError(false);
    } finally {
      closeModal();
      setFile(null);
      setIsLoading(false);
    }
  };

  const onChangeFile = (e: ChangeEvent<HTMLInputElement>): void => {
    if (e.target?.files?.[0]) {
      setFile(e.target.files[0]);
    }
  };

  const resetModal = () => {
    setFile(null);
    setHasError(false);
    setIsLoading(false);
  };

  const handleCloseModal = () => {
    closeModal();
    resetModal();
  };

  return (
    <BaseModal
      isOpen={showModal}
      onClose={handleCloseModal}
      width="w-[512px]"
      padding={false}
    >
      {hasError ? (
        <div className="rounded-md bg-red-50 p-4">
          <div className="flex">
            <div className="shrink-0">
              <XCircleIcon
                className="h-5 w-5 text-red-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <p className="text-sm font-medium text-red-800">
                Error al adjuntar documentación
              </p>
            </div>
            <div className="ml-auto pl-3">
              <div className="-mx-1.5 -my-1.5">
                <button
                  type="button"
                  onClick={handleCloseModal}
                  className="inline-flex bg-red-50 rounded-md p-1.5 text-red-500 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-red-50 focus:ring-red-600"
                >
                  <XIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="bg-white px-6 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div>
              <div className="mt-3 text-center sm:mt-0 sm:text-left">
                <Dialog.Title
                  as="h3"
                  className="text-md leading-6 font-medium text-gray-900"
                >
                  Adjuntar documentación
                </Dialog.Title>
                <div className="mt-2">
                  <div
                    className={`px-6 pt-5 pb-6 border-2 border-gray-300 ${
                      file ? 'border-solid' : 'border-dashed'
                    } rounded-md`}
                  >
                    <div className="space-y-1 text-center justify-center">
                      {file ? (
                        <ClipboardCheckIcon
                          className="h-10 w-10 text-gray-400 m-auto"
                          aria-hidden="true"
                        />
                      ) : (
                        <svg
                          className="mx-auto h-12 w-12 text-gray-400"
                          stroke="currentColor"
                          fill="none"
                          viewBox="0 0 48 48"
                          aria-hidden="true"
                        >
                          <path
                            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      )}
                      <div className="text-sm text-center text-gray-600">
                        {file ? (
                          <span>Archivo cargado</span>
                        ) : (
                          <label
                            htmlFor="file-upload"
                            className="cursor-pointer bg-white rounded-md font-medium text-blue-600 hover:text-blue-700"
                          >
                            <span>Adjuntar archivo</span>
                            <input
                              id="file-upload"
                              type="file"
                              className="sr-only"
                              name="fileUpload"
                              onChange={onChangeFile}
                              accept="image/*, application/pdf"
                            />
                          </label>
                        )}
                      </div>
                      {!file && (
                        <p className="text-xs text-gray-500">
                          PNG, JPG, PDF hasta 25 MB
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button
              type="button"
              className="cursor-pointer w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={uploadDocument}
              disabled={isLoading || !file}
            >
              {isLoading && (
                <Spinner color="text-white" size={4} marginTop={1} />
              )}
              {isLoading ? 'Guardando...' : 'Guardar'}
            </button>
            <button
              type="button"
              className="cursor-pointer mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={handleCloseModal}
            >
              Cancelar
            </button>
          </div>
        </>
      )}
    </BaseModal>
  );
}

export default UploadFileModal;
