import React, { useContext, useEffect, useReducer, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';

import Pagination from '../components/Pagination';
import { queryParamsContext } from '../context/QueryParamsContext/QueryParamsContext';
import TrafficReportTableSkeletonLoader from './components/TrafficReportTableSkeletonLoader/TrafficReportTableSkeletonLoader';
import TrafficReportsTable from './components/TrafficReportsTable/TrafficReportsTable';
import NewTrafficReportModal from '../modals/NewTrafficReportModal/NewTrafficReportModal';
import AddPaymentMethodAlert from '../components/AddPaymentMethodAlert';
import { checkAccountHasPaymentMethod } from '../services/services';
import { userInfoContext } from '../context/UserInfoContext/UserInfoContext';

export interface TrafficReportForTable {
  id: string;
  plate: string;
  frameNumber: string;
  email: string;
  s3Key: string;
  createdAt: string;
}

export interface TrafficReportsTableProps {
  trafficReports: TrafficReportForTable[];
}

type TrafficReportFromQuery = {
  id: string;
  accountId: string;
  plate: string;
  frameNumber: string;
  feeId: string;
  s3Key: string;
  createdAt: string;
  updatedAt: string;
};

const LIMIT = 10;

export default function TrafficReportsList() {
  const [loadingTrafficReports, setLoadingTrafficReports] = useState(true);
  const [trafficReports, setTrafficReports] = useState<TrafficReportForTable[]>(
    [],
  );
  const [updater, forceUpdate] = useReducer((x) => x + 1, 0);
  const { getSingleParam, setSingleParam, params } =
    useContext(queryParamsContext);
  const [page, setPage] = useState(
    getSingleParam('page') ? Number(getSingleParam('page')) : 0,
  );
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [accountHasPaymentMethod, setAccountHasPaymentMethod] = useState<
    boolean | null
  >(null);

  useEffect(() => {
    setSingleParam('page', page.toString());
  }, [page]);

  const { accountInfo } = useContext(userInfoContext);

  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    (async () => {
      const token = await getAccessTokenSilently();

      const response = await checkAccountHasPaymentMethod(
        accountInfo?.id,
        token,
      );

      setAccountHasPaymentMethod(response);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      setLoadingTrafficReports(true);
      const token = await getAccessTokenSilently();

      const {
        data: { data: fetchedTrafficReports, count },
      } = await axios.get(
        `${process.env.REACT_APP_SAAB_URL}/traffic-report?${params}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      setTrafficReports(
        fetchedTrafficReports.map((t: TrafficReportFromQuery) => ({
          id: t.id,
          plate: t.plate,
          frameNumber: t.frameNumber,
          s3Key: t.s3Key,
          createdAt: t.createdAt,
        })),
      );
      setTotalCount(count);
      setTotalPages(Math.ceil(count / LIMIT));
      setLoadingTrafficReports(false);
    })();
  }, [params, updater]);

  return (
    <>
      <div className="bg-gray-100 p-6 w-full">
        <div className="flex flex-col gap-4">
          {accountHasPaymentMethod === false && <AddPaymentMethodAlert />}
          <div>
            <div className="flex justify-between items-center">
              <h1 className="text-3xl font-semibold">Informes</h1>
              <NewTrafficReportModal
                reloadTrafficReports={forceUpdate}
                accountHasPaymentMethod={Boolean(accountHasPaymentMethod)}
              />
            </div>
            {loadingTrafficReports ? (
              <TrafficReportTableSkeletonLoader />
            ) : (
              <TrafficReportsTable trafficReports={trafficReports} />
            )}
          </div>
        </div>
      </div>
      <Pagination
        limit={LIMIT}
        page={page}
        setPage={setPage}
        totalPages={totalPages}
        totalCount={totalCount}
      />
    </>
  );
}
