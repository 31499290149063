import React, { useContext, useState, SyntheticEvent, DragEvent } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import Spinner from './Spinner';
import useUploadFile from '../hooks/useUploadFile';
import { editUser, editVehicle, editCompany } from '../services/services';
import { FILE_TYPE, User, Company, Car } from '../types/types';
import UploadFileModal from '../Modules/Documents/DocumentsTable/UploadModal';
import { toasterContext } from '../context/ToasterContext/ToasterContext';
import { fileTypeDictionary } from '../enums/fileTypeDictionary';
import { ServiceTypeI } from '../enums/serviceTypeDict';
import { skodaOCR } from '../services/skoda';

export default function PlaceholderToFill({
  fileType,
  owner,
  forceUpdate,
}: {
  fileType: FILE_TYPE;
  owner: User | Company | Car;
  forceUpdate: () => void;
}) {
  const [showModal, setShowModal] = useState(false);
  const [dragActive, setDragActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { setToasterData } = useContext(toasterContext);

  const { uploadFile, getS3SignedUrl } = useUploadFile();
  const { getAccessTokenSilently } = useAuth0();

  const handleShowModal = (): void => {
    setShowModal(true);
  };

  const handleCloseModal = (): void => {
    setShowModal(false);
  };

  const handleDrag = (e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = async (e: DragEvent<HTMLDivElement>): Promise<void> => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files) {
      const token = await getAccessTokenSilently();
      try {
        const file = e.dataTransfer.files[0];
        setIsLoading(true);
        const s3KeyName = await uploadFile(file);
        const uri = await getS3SignedUrl(s3KeyName);

        switch (fileType) {
          case FILE_TYPE.DOI_BACK: {
            const ocrData = await skodaOCR('doi', {
              uri,
            });
            const { city, address, ccaa, province, zipCode } = ocrData;
            await editUser(token, owner.id, {
              city,
              address,
              ccaa,
              province,
              zipCode,
              photoDni: [(owner as User)?.photoDni?.[0], s3KeyName],
            });
            break;
          }
          case FILE_TYPE.DOI_FRONT: {
            const ocrData = await skodaOCR('doi', {
              uri,
            });
            const {
              dni,
              name,
              firstSurname,
              secondSurname,
              birthDate,
              sex,
              expiryDate,
            } = ocrData;
            await editUser(token, owner.id, {
              dni,
              name,
              surname: `${firstSurname} ${secondSurname}`,
              birthDate,
              sex,
              dniExpiryDate: expiryDate,
              photoDni: [s3KeyName, (owner as User)?.photoDni?.[1]],
            });
            break;
          }
          case FILE_TYPE.CIRCULATION_PERMIT: {
            const ocrData = await skodaOCR('circulation_permit', {
              uri,
            });
            const {
              plate,
              enrollmentDate,
              brand,
              model,
              frameNumber,
              powerKw,
              cc,
              serviceType,
              fuel,
              kms,
            } = ocrData;
            await editVehicle(token, {
              id: owner.id,
              plate,
              enrollmentDate,
              brand,
              model,
              kms,
              cc,
              powerKw,
              frameNumber,
              serviceType: serviceType as ServiceTypeI,
              fuel,
              circulationPermit: s3KeyName,
            });
            break;
          }
          case FILE_TYPE.TECHNICAL_SHEET:
            await editVehicle(token, {
              id: owner.id,
              technicalSheet: s3KeyName,
            });
            break;
          case FILE_TYPE.TIF: {
            const ocrData = await skodaOCR('tif', {
              uri,
            });
            const {
              nif,
              legalName,
              fiscalAddressZipCode,
              fiscalAddressState,
              fiscalAddressProvince,
              fiscalAddressCity,
              fiscalAddressAddress,
            } = ocrData;
            await editCompany(token, (owner as Company).id, {
              nif,
              legalName,
              nifFile: s3KeyName,
              fiscalAddressZipCode,
              fiscalAddressState,
              fiscalAddressProvince,
              fiscalAddressCity,
              fiscalAddressAddress,
            });
            break;
          }
          default:
            break;
        }
        setToasterData({
          type: 'SUCCESS',
          title: 'Documento subido correctamente',
          message: 'Documentación guardada y procesada correctamente',
        });
      } catch (err) {
        setToasterData({
          message:
            'No se han podido procesar los archivos. Por favor, inténtelo de nuevo más tarde o contacta con nosotros.',
          title: 'Error de lectura',
          type: 'ERROR',
        });
      } finally {
        forceUpdate();
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      <UploadFileModal
        closeModal={handleCloseModal}
        showModal={showModal}
        fileType={fileType}
        actionAppliedTo={owner}
        forceUpdate={forceUpdate}
      />
      <div
        role="button"
        tabIndex={0}
        aria-hidden="true"
        onClick={handleShowModal}
        className="mx-4 my-4 aspect-h-1 aspect-w-1 w-full flex-shrink-0 overflow-hidden rounded-lg sm:aspect-none sm:h-40 sm:w-40"
      >
        <div
          onDragEnter={handleDrag}
          onSubmit={(e) => e.preventDefault()}
          className="h-full flex items-center justify-center border-2 border-r-2 border-dashed rounded-sm p-10"
        >
          {isLoading ? (
            <Spinner color="text-blue-700" size={8} marginTop={0} />
          ) : (
            <>
              {dragActive && (
                <div className="h-44 flex items-center">
                  <p className="text-sm text-center">Suelta tu archivo</p>
                </div>
              )}
              {!dragActive && (
                <div className="flex flex-col items-center justify-center">
                  <svg
                    className="mx-auto h-12 w-12 text-gray-400"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 48 48"
                    aria-hidden="true"
                  >
                    <path
                      d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <span className="text-gray-400 text-sm text-center">
                    <span className="block">Añadir</span>
                    {fileTypeDictionary(fileType)}
                  </span>
                </div>
              )}
            </>
          )}
        </div>
        {dragActive && (
          <div
            className="absolute w-full h-full top-0 right-0 bottom-0 left-0 p-10"
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          />
        )}
      </div>
    </>
  );
}
