import React from 'react';
import ReactDOM from 'react-dom';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import { IoMdContact } from 'react-icons/io';

import './index.css';
import 'react-tooltip/dist/react-tooltip.css';
import Main from './routes/main';
import Spinner from './components/Spinner';

function Home() {
  const { loginWithRedirect } = useAuth0();
  return (
    <div className="h-full bg-zinc-50">
      <main className="flex flex-col h-full w-full py-12 px-10 gap-4">
        <nav
          className="absolute flex items-center justify-between"
          aria-label="Global"
        >
          <a href="https://swipoo.com" target="_blank" rel="noreferrer">
            <span className="sr-only">Swipoo.com</span>
            <img
              alt="logo"
              className="h-8 w-auto sm:h-10"
              src="https://elreferente.es/wp-content/uploads/2022/01/Logo_swipoo.png"
            />
          </a>
        </nav>
        <section className="flex flex-col items-center min-w-fit lg:items-start justify-center gap-20 h-full">
          <h1 className="flex flex-col items-center lg:items-start gap-1 text-4xl md:text-6xl 2xl:text-7xl tracking-tight font-extrabold text-gray-900">
            Gestoría online
            <span className="text-center text-blue-600">de automoción</span>
          </h1>
          <button
            onClick={loginWithRedirect}
            type="button"
            className="flex items-center justify-center gap-3 w-fit px-5 py-2.5 text-base md:text-md md:px-8 md:py-4 md:text-lg font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:ring-4 focus:ring-indigo-300 rounded-lg"
          >
            <span className="text-2xl -ml-1.5">
              <IoMdContact />
            </span>
            Acceder
          </button>
        </section>
      </main>
      <div
        className="hidden lg:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-6/12 xl:w-8/12"
        style={{ 'clip-path': 'polygon(25% 0, 100% 0, 100% 100%, 0% 100%)' }}
      >
        <img
          className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
          src="https://images.unsplash.com/photo-1585390062628-be8608aa7d83?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2850&q=80"
          alt="gestion online"
        />
      </div>
    </div>
  );
}

function LoginPresentation() {
  const { isAuthenticated, isLoading } = useAuth0();

  if (isLoading)
    return (
      <div className="w-screen h-screen flex flex-col justify-center items-center gap-y-4">
        <img
          src="/swipoo-logo.svg"
          alt="swipoo logo"
          width="180"
          height="180"
        />
        <Spinner color="text-blue-700" size={10} marginTop={28} />
      </div>
    );
  if (isAuthenticated) return <Main />;
  return <Home />;
}

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain="swipoo.eu.auth0.com"
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      redirectUri={window.location.origin}
      audience={
        window.location.hostname === 'localhost'
          ? 'https://api-sandbox.swipoo.com'
          : process.env.REACT_APP_BASE_API_URL
      }
    >
      <LoginPresentation />
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);
